import React from 'react';
import { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Nav from './Nav';
import Shop from './Shop';
import About from './About';
import ItemDetail from './ItemDetail';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Facebook from './Facebook';





function App() {
  console.log("funkcija APpp");
    
    return(
      <Router>
        <div className="App">
          <Nav />
          <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/shop" exact component={Shop}/>
            <Route path="/shop/:id" component={ItemDetail}/>
            <Route path="/login" component={Facebook}/>
          </Switch>
        </div>
      </Router>





      );





}

const Home = () => (
  <div>
    <h1>Home page</h1>
  </div>

);





















export default App;
