import React, {useState, useEffect, Component} from 'react';
import './App.css';
import {Link} from 'react-router-dom';








class Shop extends Component{

    constructor(props){
    super(props);
    this.state = {
      items: [],
      isLoiaded: false,
    }
  }

  componentDidMount(){
    fetch('https://reqres.in/api/users?page=2')
      .then(res => res.json())
      .then(json =>{
        this.setState({
          isLoiaded:true,
          items: json.data,
        })
      });
  }



  render() {

    var { isLoiaded, items } = this.state;
    if(!isLoiaded){
      return <div>Loading...</div>;
    }
    else{
      console.log(items);
      return(
        <div className="App">
          
            {items.map((item) => 
              <h1 key="{item.id}">
                <Link to={`/shop/${item.id}`}>Email: {item.email}</Link>
              </h1>
            )};

          


        </div>
      );
    }



  }
}

export default Shop;