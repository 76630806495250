import React from 'react';
import './App.css';

function About(){
	alert("stran about");
	return(
	<div>
		<h1>About</h1>
	</div>
	);

}

export default About;