import React from 'react';
import { Component } from 'react';
import FacebookLogin from 'react-facebook-login';


	function Welcome(props) {
  		return <h1>Hello, {props.name}</h1>;
		}
		const element = <Welcome name="Sara" />;

	function logOut(props){
			alert("logOut");
			alert("trenutniAccessToken:"+localStorage.getItem("FBLoginToken")+"");
			localStorage.setItem("FBLoginToken", "");
			alert("Izbrisan trenutniAccessToken:"+localStorage.getItem("FBLoginToken")+"");
			//console.log("state", state);
		console.log("logout funkcija");
			
	}



export default class Facebook extends Component {
	state = {
		isLoggedIn: false,
		userID:'',
		name:'',
		email: '',
		picture: ''
	}


	responseFacebook = response => {
		//console.log(response);

		this.setState({
			isLoggedIn: true,
			userID: response.userID,
			name: response.name,
			email: response.email,
			picture: response.picture.data.url
		})
		localStorage.setItem("FBLoginToken", response.accessToken);
		console.log("Facebook login response", response);

	}







	componentClicked = () => console.log("clicked");


	render(){
		let fbContent;

		if(this.state.isLoggedIn){
			fbContent = (
				<div style={{
					width:'400px',
					margin: 'auto',
					background: '#f4f4f4',
					padding: '20px'
				}}>

					<img src={this.state.picture} alt={this.state.name}/>
					<h2>Welcome {this.state.name}</h2>
					Email: {this.state.email}
					<a href="#" onClick={(e)=>{e.preventDefault(); logOut();}}>logout</a>
					{element}
					</div>



				);

			alert("testiranje buildanja aplikacije.");
			alert(window.location.href);
		}
		else{
			alert(window.location.href);
		
			fbContent = (
				  <FacebookLogin
				    appId="278984899784950"
				    autoLoad={true}
				    fields="name,email,picture"
				    onClick={this.componentClicked}
				    callback={this.responseFacebook} />

				);
		}

			console.log("fbContent", fbContent);
		return(
			<div>{fbContent}


				<h1>TEST</h1>
			</div>
			)
	}
}