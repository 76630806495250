import React, {useState, useEffect, Component} from 'react';
import './App.css';
import {Link} from 'react-router-dom';
import Nav from './Nav';
import Shop from './Shop';
import About from './About';









class ItemDetail extends Component{

    constructor(props){
    super(props);
    this.state = {
      items: [],
      isLoiaded: false,
    }
  }

  componentDidMount(){
    var userId=this.props.match;
    var userId1 = userId.url;
    console.log(userId1);
    var userId2 = userId1.split("/");
    console.log(userId2[2]);
    
    fetch(`https://reqres.in/api/users/${userId2[2]}`)
      .then(res => res.json())
      .then(json =>{
        this.setState({
          isLoiaded:true,
          items: json.data,
        })
      });
  }



  render() {

    var { isLoiaded, items } = this.state;
    if(!isLoiaded){
      return <div>Loading...</div>;
    }
    else{
      console.log(items);
      return(
        <div className="App">
          
          <h1>{items.first_name}</h1>
          <h1>{items.last_name}</h1>
          <h1>{items.avatar}</h1>
          <h1>{items.id}</h1>
          <h1>{items.email}</h1>


         


        </div>
      );
    }



  }
}

export default ItemDetail;